import { post } from './api';

export const questionerAPI = {
  addQuestioner(questionerData) {
    return post('questioner', questionerData);
  },

  strictMenu(questionerData) {
    return post('strict-menu', questionerData);
  },

  remindMe(userData) {
    return post('remind-me', userData);
  },
};
