import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { floatValidation, poundsValidation } from '../../utils/functions';
import ProgressBar from '../ProgressBar/ProgressBar';
import RadioButton from '../RadioButton/RadioButton';

import styles from './Steps.module.css';

const CENTIMETER = 'cm';
const FOOT = 'foot';

const heightPlaceholder = {
  [CENTIMETER]: 'For example: 169',
  [FOOT]: 'For example: 5’8”',
};

const Step5 = (props) => {
  const { onSave, stepsData } = props;

  const [heightUnit, setHeightUnit] = useState(stepsData.heightUnit || CENTIMETER);
  const [height, setHeight] = useState(stepsData.height || '');
  const [error, setError] = useState(null);
  const [afterChecking, setAfterChecking] = useState(false);

  const validate = useCallback(() => {
    let error = null;

    if (heightUnit === FOOT) {
      if (!poundsValidation(height)) {
        error = 'Height must be in the correct format';
      }
    } else if (!floatValidation(height)) {
      error = 'Height must be number';
    }

    setError(error);
    return !error;
  }, [height, heightUnit]);

  useEffect(() => {
    if (afterChecking) {
      validate();
    }
  }, [afterChecking]);

  useEffect(() => {
    console.log('==CHANGE UNIT', heightUnit, height);
    if (height) {
      validate();
    }
  }, [validate, heightUnit]);

  const onChangeUnit = (event) => {
    setHeightUnit(event.target.value);
  };

  const onChangeHeight = (event) => {
    setHeight(event.target.value);
  };

  const onSaveHandler = () => {
    if (!validate()) {
      return setAfterChecking(true);
    }

    onSave({ height, heightUnit });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.greenBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        How tall are you?
      </div>
      <div className={styles.spaceBetween}>
        <div>
          <div className={styles.radioButtons}>
            <RadioButton
              name="heightUnit"
              value={CENTIMETER}
              onChange={onChangeUnit}
              checked={heightUnit === CENTIMETER}
              label="cm"
            />
            <RadioButton
              name="heightUnit"
              value={FOOT}
              onChange={onChangeUnit}
              checked={heightUnit === FOOT}
              label="foot"
            />
          </div>
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              onChange={onChangeHeight}
              value={height}
              placeholder={heightPlaceholder[heightUnit]}
            />
            {error && <div className={styles.error}>{error}</div>}
          </div>
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!height}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step5;