import React, { useState } from 'react';
import cn from 'classnames';

import SelectButtons from '../../components/SelectButtons/SelectButtons';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const howActiveItems = [
  {
    value: 'passive',
    label: 'Sedentary',
  },
  {
    value: 'moderate',
    label: 'Exercise 1-3 times per week',
  },
  {
    value: 'highly',
    label: 'Exercise 4-5 times per week',
  },
  {
    value: 'sportsmen',
    label: 'Professional sportsman',
  },
];

const Step6 = (props) => {
  const { onSave, stepsData } = props;

  const [physicalActivity, setPhysicalActivity] = useState(stepsData.physicalActivity);
  const [error, setError] = useState(null);

  const onChangeHandler = (value) => {
    setPhysicalActivity(value);
    setError(null);
  };

  const onSaveHandler = () => {
    onSave({ physicalActivity });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.brownBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={cn(styles.stepHeader, styles.nowrap)}>
        How active are you?
      </div>
      <div className={styles.spaceBetween}>
        <div className={styles.selectButtonsWrapper}>
          <SelectButtons
            buttonsData={howActiveItems}
            onChange={onChangeHandler}
            defaultValue={physicalActivity}
          />
          {error && <div className={styles.error}>{error}</div>}
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!physicalActivity}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step6;