import React, { useState } from 'react';
import cn from 'classnames';

import { excludeMealItems } from '../../constants/excludeMeals';
import MultipleSelectButtons from '../../components/MultipleSelectButtons/MultipleSelectButtons';
import ContactMePage from './ContactMePage';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const Step7 = (props) => {
  const { onSave, stepsData } = props;

  const [excludedMeals, setExcludedMeals] = useState(stepsData.excludedMeals || null);
  const [showContactForm, setShowContactForm] = useState(false);

  const onChangeHandler = (value) => {
    setExcludedMeals(value);
  };

  const saveMeals = (email) => onSave({ excludedMeals, email });

  const onSaveHandler = () => {
    if (excludedMeals.length > 2) {
      setShowContactForm(true);
    } else {
      saveMeals();
    }
  };

  if (showContactForm) {
    return (
      <ContactMePage stepsData={{ ...stepsData, excludedMeals }} />
    );
  }

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.greenBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What would you like to exclude from your meal plan?
      </div>
      <div className={styles.spaceBetween}>
        <div className={styles.selectButtonsWrapper}>
          <MultipleSelectButtons
            buttonsData={excludeMealItems}
            onChange={onChangeHandler}
            defaultValue={excludedMeals}
          />
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!excludedMeals}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step7;