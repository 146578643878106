import React from 'react';
import cn from 'classnames';

import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Steps.module.css';

const Step2 = (props) => {
  const { onSave } = props;

  const selectMan = () => onSave({ gender: 'man' });
  const selectWoman = () => onSave({ gender: 'woman' });

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.brownBackground)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What is your gender by birth?
      </div>
      <div className={styles.genderList}>
        <div onClick={selectMan}>Male</div>
        <div onClick={selectWoman}>Female</div>
      </div>
    </div>
  );
};

export default Step2;