import React, { useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { remindMe } from '../../actions/steps';
import EmailForm from './EmailForm';
import FinishPage from './FinishPage';
import styles from './Steps.module.css';

const RemindMePage = (props) => {
  const { remindMe } = props;
  const [showFinish, setShowFinish] = useState(false);

  const saveHandler = (userData) => {
    remindMe(userData, () => setShowFinish(true));
  };
  

  if (showFinish) {
    return <FinishPage/>;
  }

  return (
    <div className={cn(
      styles.stepWrapper,
      styles.wrapperPadding,
      styles.brownBackground,
      styles.flexWrapper,
      styles.emailForm
    )}>
      <div className={styles.stepHeader}>
        Let’s stay in touch!
      </div>
      <EmailForm onSave={saveHandler} />
    </div>
  );
};

const mapDispatchToProps = {
  remindMe,
};

export default connect(null, mapDispatchToProps)(RemindMePage);
