import React, { useState } from 'react';
import cn from 'classnames';

import ProgressBar from '../ProgressBar/ProgressBar';
import EmailForm from './EmailForm';
import FinishPage from './FinishPage';
import styles from './Steps.module.css';

const Step10 = (props) => {
  const { onSave } = props;
  const [showFinish, setShowFinish] = useState(false);

  const saveHandler = (formData) => {
    onSave(formData, () => {
      setShowFinish(true);
    });
  };

  if (showFinish) {
    return <FinishPage />;
  }

  return (
    <div className={cn(
      styles.stepWrapper,
      styles.wrapperPadding,
      styles.brownBackground,
      styles.flexWrapper,
      styles.emailForm
    )}>
      <ProgressBar onSave={onSave} />
      <div className={styles.stepHeader}>
        Submit your email to receive your customized meal plan
      </div>
      <EmailForm onSave={saveHandler} />
    </div>
  );
};

export default Step10;