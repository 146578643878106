import _ from 'lodash';

import config from '../config';
import { getLocalStorageObject, setLocalStorageObject } from '../utils/functions';
import { questionerAPI } from '../api/questioner';
import { actionWrapper } from './actionWrapper';

export const SET_STEPS = 'SET_STEPS';
export const RESET_STEPS = 'RESET_STEPS';

const STEPS_FIELD_NAME = 'steps';

export const getStepsData = () => (dispatch) => {
  const stepsData = getLocalStorageObject(STEPS_FIELD_NAME);

  console.log('===INIT stepsData', stepsData);

  if (!_.isEmpty(stepsData)) {
    // stepsData.step = 0;
    dispatch(setStepsData(stepsData));
  }
};

export const setStepsData = (stepsData) => {
  setLocalStorageObject(STEPS_FIELD_NAME, stepsData);

  return {
    type: SET_STEPS,
    payload: stepsData,
  };
};

const clearSteps = () => {
  localStorage.removeItem(STEPS_FIELD_NAME);
};

const clearStepsWithUpdate = (dispatch) => {
  clearSteps();
  setTimeout(() => {
    dispatch({ type: RESET_STEPS });
  }, config.redirectAfterFinishTime);
};

const prepareQuestionerData = (questionerData) => {
  let { 
    birthDate,
    weightUnit,
    height, 
    heightUnit,
    physicalActivity,
    excludedMeals,
   } = questionerData;

  if (questionerData.heightUnit === 'foot' && (height.includes('’') || height.includes('\''))) {
    const delimiter = height.includes('’') ? '’' : '\'';

    const [pounds, inches] = height.split(delimiter);
    // Don't change it
    height = +(parseFloat(inches) / 12).toFixed(2) + +pounds;
  }

  return {
    ...questionerData,
    birth_date: birthDate,
    weight_unit: weightUnit,
    height,
    height_unit: heightUnit,
    physical_activity: physicalActivity,
    excluded_meals: excludedMeals,
  };
};

export const addQuestioner = (questionerData, callback) => actionWrapper(async (dispatch) => {
  const questionerDataForBack = prepareQuestionerData(questionerData);
  await questionerAPI.addQuestioner(questionerDataForBack);
  clearStepsWithUpdate(dispatch);
  callback?.();
});

export const remindMe = (userData, callback) => actionWrapper(async () => {
  await questionerAPI.remindMe(userData);
  clearSteps();
  callback?.();
});

export const resetSteps = () => {
  return {
    type: RESET_STEPS,
  };
};

export const strictMenu = (questionerData, callback) => actionWrapper(async (dispatch) => {
  const questionerDataForBack = prepareQuestionerData(questionerData);
  await questionerAPI.strictMenu(questionerDataForBack);
  clearStepsWithUpdate(dispatch);
  callback?.();
});

export const changeCountry = (country) => (dispatch, getState) => {
  const stepsData = getState().steps.stepsData;
  stepsData.country = country;
  dispatch(setStepsData(stepsData));
};