import React from 'react';
import styles from './Terms.module.css';

const TermsUS = () => {
  return (
    <>
      <p>
        <strong>Introduction </strong>
        Welcome to our service. These Terms of Use govern your use of our questionnaire to terms of Use
      </p>

      <p>
        <strong>Acceptance of Terms </strong>
        By accessing and using our meal plan creation service, you agree to comply with and be bound by the following
        terms and conditions. If you do not agree to these terms, please do not use our service.
      </p>

      <p>
        <strong>Eligibility </strong>
        Our service is intended for users in the United States. You must be at least 18 years old to use this service.
        By using this service, you represent and warrant that you meet these requirements.
      </p>

      <p>
        <strong>Personal Information </strong>
        To create a customized meal plan, we collect and use the following personal information:
      </p>

      <ul>
        <li>
          Weight
        </li>
        <li>
          Age
        </li>
        <li>
          Gender
        </li>
        <li>
          Height
        </li>
        <li>
          Physical activity level
        </li>
        <li>
          Allergies
        </li>
      </ul>

      <p>
        <strong>Data Privacy and Security </strong>
        We are committed to protecting your privacy and ensuring the security of your personal information. We will not
        share your personal information with third parties without your explicit consent, except as required by law.
      </p>
      <ol className={styles.alphabetList}>
        <li>
          <strong>Data Collection and Usage </strong>
          <ul>
            <li>
              We collect your personal information solely for the purpose of creating a personalized meal plan.

            </li>
            <li>
              Your data is stored securely and is accessible only by authorized personnel.
            </li>
          </ul>
        </li>

        <li>
          <strong>Data Protection </strong>
          <ul>
            <li>
              We use industry-standard security measures to protect your personal information from unauthorized access,
              disclosure, alteration, or destruction.
            </li>
          </ul>
        </li>
      </ol>

      <p>
        <strong>User Responsibilities </strong>
        You agree to provide accurate and complete information when using our service. You are responsible for
        maintaining the confidentiality of your account information and for all activities that occur under your
        account.
      </p>

      <p>
        <strong>Health Disclaimer </strong>
        Our meal plans are intended for informational purposes only and are not a substitute for professional medical
        advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider
        with any questions you may have regarding a medical condition.
      </p>

      <p>
        <strong>Modifications to the Service </strong>
        We reserve the right to modify or discontinue, temporarily or permanently, the service (or any part thereof)
        with or without notice. You agree that we will not be liable to you or to any third party for any modification,
        suspension, or discontinuance of the service.
      </p>

      <p>
        <strong>Termination </strong>
        We reserve the right to terminate or suspend your access to our service, without prior notice or liability, for
        any reason, including if you breach these terms.
      </p>

      <p>
        <strong>Limitation of Liability </strong>
        To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly,
        or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use
        the service; (ii) any unauthorized access to or use of our servers and/or any personal information stored
        therein.
      </p>

      <p>
        <strong>Governing Law </strong>
        These terms shall be governed and construed in accordance with the laws of the United States, without regard to
        its conflict of law provisions.
      </p>

      <p>
        <strong>Contact Information </strong>
        If you have any questions about these terms, please contact us at{' '}
        <a href="mailto: hello@mealwell.ai" target="_blank" rel="noreferrer">hello@mealwell.ai</a>.
        By using our meal plan creation service, you acknowledge that you have read, understood, and agree to be bound
        by these terms and conditions.
      </p>
    </>
  );
};

export default TermsUS;
