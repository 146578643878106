import React, { useState } from 'react';
import cn from 'classnames';

import styles from './SelectButtons.module.css';

const SelectButtons = (props) => {
  const {
    buttonsData,
    onChange,
    defaultValue = null,
  } = props;

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onChangeHandler = (value) => {
    onChange(value);
    setSelectedValue(value);
  };

  return (
    <div className={styles.wrapper}>
      {buttonsData.map((buttonData) => (
        <button
          key={buttonData.value}
          className={cn(styles.button, 'select-button', { [styles.active]: buttonData.value === selectedValue })}
          onClick={() => onChangeHandler(buttonData.value)}
        >
          {buttonData.label}
        </button>
      ))}
    </div>
  );
};

export default SelectButtons;
