import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import RadioButton from '../RadioButton/RadioButton';
import styles from './MultipleSelectButtons.module.css';

const MultipleSelectButtons = (props) => {
  const {
    buttonsData,
    onChange,
    defaultValue = [],
  } = props;

  const [selectedValues, setSelectedValues] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const selectedValues = {};

      defaultValue.forEach((value) => {
        selectedValues[value] = true;
      });

      setSelectedValues(selectedValues);
    }

  }, [defaultValue]);

  const onChangeHandler = (value) => {
    let newValues = { ...selectedValues };

    if (value) {
      if (newValues[value]) {
        delete newValues[value];
      } else {
        newValues[value] = true;
      }
    } else {
      newValues = {};
    }

    onChange(Object.keys(newValues));
    setSelectedValues(newValues);
  };

  return (
    <div className={styles.wrapper}>
      {buttonsData.map((buttonData) => (
        <button
          key={buttonData.value}
          className={cn(styles.button, { [styles.active]: selectedValues?.[buttonData.value] })}
          onClick={() => onChangeHandler(buttonData.value)}
        >
          <img src={buttonData.icon} alt={buttonData.label} />
          {buttonData.label}
        </button>
      ))}
      <div className={styles.checkboxWrapper}>
        <RadioButton
          type="checkbox"
          onChange={() => onChangeHandler()}
          checked={!!selectedValues && !Object.keys(selectedValues).length}
          label="Nothing from above"
        />
      </div>
    </div>
  );
};

export default MultipleSelectButtons;
