import dairyLogo from '../assets/images/dairy.png';
import fishLogo from '../assets/images/fish.png';
import meatLogo from '../assets/images/meat.png';
import eggsLogo from '../assets/images/eggs.png';

export const excludeMealItems = [
  {
    value: 'dairy',
    label: 'Dairy',
    icon: dairyLogo,
  },
  {
    value: 'fish',
    label: 'Fish',
    icon: fishLogo,
  },
  {
    value: 'meat',
    label: 'Meat',
    icon: meatLogo,
  },
  {
    value: 'eggs',
    label: 'Eggs',
    icon: eggsLogo,
  },
];
