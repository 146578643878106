import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';

import { floatValidation } from '../../utils/functions';
import ProgressBar from '../ProgressBar/ProgressBar';
import RadioButton from '../RadioButton/RadioButton';

import styles from './Steps.module.css';

const KILOGRAM = 'kg';
const POUND = 'lb';
const STONES = 'st';

const weightPlaceholder = {
  [KILOGRAM]: 'For example: 75',
  [POUND]: 'For example: 165',
  [STONES]: 'For example: 11.8',
};

const Step4 = (props) => {
  const { onSave, stepsData } = props;

  const [weightUnit, setWeightUnit] = useState(stepsData.weightUnit || KILOGRAM);
  const [weight, setWeight] = useState(stepsData.weight || '');
  const [error, setError] = useState(null);
  const [afterChecking, setAfterChecking] = useState(false);

  const validate = useCallback(() => {
    let error = null;

    if (!floatValidation(weight)) {
      error = 'Weight must be number';
    }

    setError(error);
    return !error;
  }, [weight]);

  useEffect(() => {
    if (afterChecking) {
      validate();
    }
  }, [validate, afterChecking]);

  const onChangeUnit = (event) => {
    setWeightUnit(event.target.value);
  };

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const onSaveHandler = () => {
    if (!validate()) {
      return setAfterChecking(true);
    }

    onSave({ weight, weightUnit });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.brownBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What is your weight?
      </div>
      <div className={styles.spaceBetween}>
        <div>
          <div className={cn(styles.radioButtons, styles.wideRadioButttons)}>
            <RadioButton
              name="weightUnit"
              value={KILOGRAM}
              onChange={onChangeUnit}
              checked={weightUnit === KILOGRAM}
              label="Kg"
            />
            <RadioButton
              name="weightUnit"
              value={POUND}
              onChange={onChangeUnit}
              checked={weightUnit === POUND}
              label="Pounds"
            />
            <RadioButton
              name="weightUnit"
              value={STONES}
              onChange={onChangeUnit}
              checked={weightUnit === STONES}
              label="Stones"
            />
          </div>
          <div className={styles.inputWrapper}>
            <input
              type="number"
              className={styles.input}
              onChange={onChangeWeight}
              value={weight}
              placeholder={weightPlaceholder[weightUnit]}
            />
            {error && <div className={styles.error}>{error}</div>}
          </div>
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!weight}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step4;