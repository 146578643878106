import React from 'react';
// import styles from './Terms.module.css';

const TermsGB = () => {
  return (
    <>
      <p>
        <strong>Introduction </strong>
        Welcome to our service. These Terms of Use govern your use of our questionnaire to generate personalised meal
        plans based on the data you provide. By using our service, you agree to these Terms of Use.
      </p>

      <p>
        <strong>Eligibility </strong>
        By using our service, you confirm that you are at least 18 years old or have obtained parental consent to use
        our service if you are under 18.
      </p>

      <p>
        <strong>Data Collection </strong>
        To generate personalised meal plans, we collect the following information:
      </p>
      <ul>
        <li>
          Weight
        </li>
        <li>
          Age
        </li>
        <li>
          Gender
        </li>
        <li>
          Height
        </li>
        <li>
          Physical activity level
        </li>
        <li>
          Allergies
        </li>
      </ul>


      <p>
        <strong>Use of Data </strong>
        The data you provide is used solely for the purpose of creating and sending you personalised meal plans. We
        ensure your data is handled in accordance with our Privacy Policy.
      </p>

      <p>
        <strong>Accuracy of Information </strong>
        You agree to provide accurate and up-to-date information. We are not responsible for any issues arising from
        incorrect or outdated information provided by you.
      </p>

      <p>
        <strong>Intellectual Property </strong>
        All content and materials provided as part of our service, including meal plans, are owned by us or our
        licensors. You may use the meal plans for personal use only and not for any commercial purpose.
      </p>

      <p>
        <strong>Limitation of Liability </strong>
        Our service is provided on an {'"'}as is{'"'} and {'"'}as available{'"'} basis. We make no warranties or
        representations about the accuracy or completeness of the meal plans. To the fullest extent permitted by law, we
        exclude all liability for any direct, indirect, incidental, or consequential loss or damage incurred by you in
        connection with your use of our service.
      </p>

      <p>
        <strong>User Responsibilities </strong>
        You are responsible for maintaining the confidentiality of any login information associated with your account
        and for all activities that occur under your account.
      </p>

      <p>
        <strong>Modifications to the Service </strong>
        We reserve the right to modify or discontinue our service at any time without notice. We are not liable for any
        modification, suspension, or discontinuation of the service.
      </p>

      <p>
        <strong>Governing Law </strong>
        These Terms of Use are governed by and construed in accordance with the laws of England and Wales. Any disputes
        arising out of or in connection with these Terms of Use shall be subject to the exclusive jurisdiction of the
        courts of England and Wales.
      </p>

      <p>
        <strong>Changes to These Terms </strong>
        We may update these Terms of Use from time to time. Any changes will be posted on this page, and we will notify
        you of any significant changes.
      </p>

      <p>
        <strong>Contact Us </strong>
        If you have any questions about these Terms of Use, please contact us at{' '}
        <a href="mailto: hello@mealwell.ai" target="_blank" rel="noreferrer">hello@mealwell.ai</a>.
      </p>

      <p>
        <strong>Last Updated </strong>
        These Terms of Use were last updated on 15.07.2024.
      </p>
    </>
  );
};

export default TermsGB;
