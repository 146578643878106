import React, { useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import config from '../../config';
import { strictMenu, resetSteps } from '../../actions/steps';
import EmailForm from './EmailForm';
import FinishPage from './FinishPage';
import styles from './Steps.module.css';

const ContactMePage = (props) => {
  const { strictMenu, resetSteps, stepsData } = props;
  const [showFinish, setShowFinish] = useState(false);

  const saveHandler = (userData) => {
    Object.assign(userData, stepsData);
    strictMenu(userData, () => {
      setShowFinish(true);
      // resetSteps();
      setTimeout(() => resetSteps(), config.redirectAfterFinishTime);
    });
  };

  if (showFinish) {
    return <FinishPage/>;
  }

  return (
    <div className={cn(
      styles.stepWrapper,
      styles.wrapperPadding,
      styles.greenBackground,
      styles.flexWrapper,
      styles.emailForm
    )}>
      <div className={styles.stepHeader}>
        Unfortunately, your diet is too strict and we don’t know yet how to create a balanced
        meal plan for you. We will contact you as soon as we have a solution for you.
      </div>
      <EmailForm onSave={saveHandler} />
    </div>
  );
};

const mapDispatchToProps = {
  strictMenu,
  resetSteps,
};

export default connect(null ,mapDispatchToProps)(ContactMePage);