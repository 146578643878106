import React from 'react';
import { connect } from 'react-redux';

import TermsGB from './TermsGB';
import TermsUS from './TermsUS';

import styles from './Terms.module.css';

const terms = {
  GB: TermsGB,
  US: TermsUS,
};

const Terms = ({ country }) => {
  console.log('===TERMS COUNTRY', country);
  const TermsComponent = terms[country];
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo} />
      <h1>Terms of use</h1>
      <TermsComponent />
    </div>
  );
};

const mapStateToProps = (store) => ({
  country: store.steps.stepsData.country,
});

export default connect(mapStateToProps)(Terms);
