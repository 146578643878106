import React, { useState } from 'react';
import cn from 'classnames';

import RemindMePage from './RemindMePage';
import styles from './Steps.module.css';

const Step1 = (props) => {
  const { onSave } = props;
  const [showRemindPage, setShowRemindPage] = useState(false);

  if (showRemindPage) {
    return <RemindMePage />;
  }

  return (
    <div className={cn(styles.stepWrapper, styles.firstPage, styles.greenBackground)}>
      <div className={styles.contentBlock}>
        <p className={styles.stepHeader}>
          Let&apos;s create a meal plan for you
        </p>
        <div className={styles.stepBody}>
          <button
            className={styles.brownButton}
            onClick={() => onSave()}
          >
            Start
          </button>
          <button
            className={styles.transparentButton}
            onClick={() => setShowRemindPage(true)}
          >
            Remind me later
          </button>
        </div>
      </div>
      <div className={styles.roundSlider} />
    </div>
  );
};

export default Step1;
