import React, { useState } from 'react';
import cn from 'classnames';

import ProgressBar from '../ProgressBar/ProgressBar';
import ContactMePage from './ContactMePage';
import styles from './Steps.module.css';

const excludeMealList = [
  'dairy',
  'fish',
  'meat',
  'egg',
];

const Step8 = (props) => {
  const { onSave, stepsData } = props;

  const [allergies, setAllergies] = useState(stepsData.allergies || '');
  const [showContactForm, setShowContactForm] = useState(false);

  const onChangeHandler = (event) => {
    setAllergies(event.target.value);
  };

  const onSaveHandler = () => {
    let excludedMealsQuantity = stepsData.excludedMeals.length;
    if (allergies) {
      const allergiesLowerCase = allergies.toLowerCase();
      excludeMealList.forEach(meal => {
        if (allergiesLowerCase.includes(meal)) {
          excludedMealsQuantity++;
        }
      });

      if (excludedMealsQuantity >= 3) {
        return setShowContactForm(true);
      }
    }

    onSave({ allergies });
  };

  if (showContactForm) {
    return (
      <ContactMePage stepsData={{ ...stepsData, allergies }} />
    );
  }

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.brownBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        Any allergies or intolerances?
      </div>
      <div className={styles.spaceBetween}>
        <div className={styles.inputWrapper}>
          <textarea
            className={styles.textarea}
            onChange={onChangeHandler}
            value={allergies}
            placeholder="Type here"
          />
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step8;