import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import ScrollToTop from '../../hoc/ScrollToTop';
import { updateViewportHeight } from '../../utils/functions';
import { getStepsData } from '../../actions/steps';
import Preloader from '../../components/Preloader/Preloader';
import Dashboard from '../Dashboard/Dashboard';
import Terms from '../Terms/Terms';
import Privacy from '../Terms/Privacy';
import PageNotFound from '../PageNotFound/PageNotFound';

import styles from './App.module.css';

function App (props) {
  const { isFetching, getStepsData } = props;

  useEffect(() => {
    getStepsData();
    updateViewportHeight();
  }, []);

  const renderCommonRoutes = () => (
    <>
      <Route path="*" Component={PageNotFound}/>
    </>
  );

  return (
    <div className={styles.wrapper}>
      {isFetching && <Preloader/>}
      <Toaster />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" Component={Dashboard} />
        <Route exact path="/terms" Component={Terms} />
        <Route exact path="/privacy" Component={Privacy} />
        {renderCommonRoutes()}
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isFetching: state.preloader.isFetching,
});

const mapDispatchToProps = {
  getStepsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);