import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import steps from '../../components/Steps/steps';
import { setStepsData } from '../../actions/steps';

import styles from './ProgressBar.module.css';

const ProgressBar = (props) => {

  const { stepsData, setStepsData } = props;

  const percent = (stepsData.step + 1) / steps.length * 100;

  const prevStep = () => {
    stepsData.step--;
    setStepsData({ ...stepsData });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.backButton} onClick={prevStep} />
      <progress
        max="100"
        value={percent}
        className={cn(styles.progress, 'progress-bar')}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  stepsData: state.steps.stepsData,
});

const mapDispatchToProps = {
  setStepsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
