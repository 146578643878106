import { SET_STEPS, RESET_STEPS, } from '../actions/steps';

const defaultStepsData = {
  step: 0,
  country: 'GB',
};


let initialState = {
  stepsData: { ...defaultStepsData },
};

const steps = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEPS:
      return {
        ...state,
        stepsData: { ...action.payload },
      };

    case RESET_STEPS:
      return {
        ...state,
        stepsData: { ...defaultStepsData },
      };

    default:
      return state;
  }
};

export default steps;
