import React, { useState } from 'react';
import cn from 'classnames';

import DatePicker from '../DatePicker/DatePicker';
import ProgressBar from '../ProgressBar/ProgressBar';

import styles from './Steps.module.css';

const Step3 = (props) => {
  const { onSave, stepsData } = props;

  const [birthDate, setBirthDate] = useState(stepsData.birthDate || '');

  const onChangeHandler = (date) => {
    setBirthDate(date);
  };

  const onSaveHandler = () => {
    onSave({ birthDate });
  };

  return (
    <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.greenBackground, styles.flexWrapper)}>
      <ProgressBar />
      <div className={styles.stepHeader}>
        What is your date of birth?
      </div>
      <div className={styles.spaceBetween}>
        <div className={styles.inputWrapper}>
          <DatePicker
            initialValue={birthDate}
            onChange={onChangeHandler}
            placeholder="Select date"
          />
        </div>
        <button
          className={styles.brownButton}
          onClick={onSaveHandler}
          disabled={!birthDate}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step3;