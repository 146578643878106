import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';
import cn from 'classnames';

import { emailValidation } from '../../utils/functions';
import { changeCountry } from '../../actions/steps';
import RadioButton from '../RadioButton/RadioButton';
import styles from './Steps.module.css';

const EmailForm = (props) => {
  const { onSave, changeCountry, stepsData } = props;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState(stepsData.country);
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(null);
  const [afterChecking, setAfterChecking] = useState(false);

  const validate = useCallback(() => {
    let error = null;

    if (!emailValidation(email)) {
      error = 'Please enter a valid email address';
    }

    setError(error);
    return !error;
  }, [email]);

  useEffect(() => {
    if (afterChecking) {
      validate();
    }
  }, [validate, afterChecking]);

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
  };

  const onSaveHandler = () => {
    if (!validate()) {
      return setAfterChecking(true);
    }

    onSave({ email, name, country });
  };

  const onChangeTerms = () => {
    setTerms(!terms);
  };

  const onChangeCountry = (country) => {
    changeCountry(country);
    setCountry(country);
  };

  const renderTermsText = () => (
    <div className={styles.termsLabel}>
      I agree to the{' '}
      <Link className={styles.link} to="/terms" target="_blank">Terms of Service</Link>{' '}
      and{' '}
      <Link className={styles.link} to="/privacy" target="_blank">Privacy Policy</Link>
    </div>
  );

  return (
    <div className={cn(styles.spaceBetween, styles.plateAnimation)}>
      <div className={styles.inputsBlock}>
        <div className={styles.inputWrapper}>
          <div>
            <p className={styles.label}>Name</p>
            <input
              className={styles.input}
              onChange={onChangeNameHandler}
              value={name}
              placeholder="Enter your name"
            />
          </div>
          <div>
            <p className={styles.label}>Email</p>
            <input
              type="email"
              className={cn(styles.input, error && styles.inputError)}
              onChange={onChangeEmailHandler}
              value={email}
              placeholder="Enter your E-mail address"
            />
            {error && <div className={styles.error}>{error}</div>}
          </div>
          <div>
            <p className={styles.label}>Country</p>
            <ReactFlagsSelect
              className={styles.input}
              countries={['GB', 'US']}
              customLabels={{ GB: 'United Kingdom', US: 'United States' }}
              onSelect={onChangeCountry}
              selected={country}
            />
          </div>
        </div>
        <div className={styles.termsCheckbox}>
          <RadioButton
            type="checkbox"
            onChange={onChangeTerms}
            checked={terms}
            label={renderTermsText()}
          />
        </div>
      </div>
      <button
        className={styles.brownButton}
        onClick={onSaveHandler}
        disabled={!name || !email || !terms}
      >
        Confirm
      </button>
    </div>
  );
};

const mapStateToProps = (store) => ({
  stepsData: store.steps.stepsData,
});

const mapDispatchToProps = {
  changeCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);