import React from 'react';
import cn from 'classnames';

import styles from './RadioButton.module.css';

const RadioButton = (props) => {
  const {
    type = 'radio',
    label,
    ...inputProps
  } = props;

  return (
    <label className={cn(styles.radioWrapper, 'radio-wrapper')}>
      <input
        type={type}
        {...inputProps}
      />
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
