import { authAPI } from '../api/auth';
import { setUserData, updateUserData } from '../utils/functions';
import { actionWrapper } from './actionWrapper';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PART_USER_DATA = 'SET_PART_USER_DATA';

const loginProceed = (userData) => {
  setUserData(userData);

  return {
    type: SET_USER_DATA,
    payload: userData,
  };
};

const logoutProceed = () => {
  return {
    type: SET_USER_DATA,
    payload: null,
  };
};

export const registration = (registrationData, callback) => actionWrapper(async () => {
  const authData = await authAPI.registration(registrationData);
  callback?.();
  return loginProceed(authData);
}, (error) => {
  console.log('===registration error', error);
});

export const login = (loginData, callback) => actionWrapper(async () => {
  const authData = await authAPI.login(loginData);
  callback?.();
  return loginProceed(authData);
}, (error) => {
  console.log('===login error', error);
});

export const googleAuth = (code, callback, errorCallback) => actionWrapper(async () => {
  const authData = await authAPI.googleAuth(code);
  callback?.();
  return loginProceed(authData);
}, errorCallback);

export const facebookAuth = (code, callback, errorCallback) => actionWrapper(async () => {
  const authData = await authAPI.facebookAuth(code);
  callback?.();
  return loginProceed(authData);
}, errorCallback);

export const logout = () => () => {
  localStorage.removeItem('user');
  window.location = '/';
  return logoutProceed();
};

export const setPartUserData = (profile) => {
  updateUserData(profile);

  return {
    type: SET_PART_USER_DATA,
    payload: profile,
  };
};
