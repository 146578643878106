const siteURL = 'http://localhost:3000';
const config = {
  siteURL,
  backURL: 'http://localhost:3002/api/v1/',
};

export default config;




