import React from 'react';
import { connect } from 'react-redux';

import { setStepsData, addQuestioner } from '../../actions/steps';
import steps from '../../components/Steps/steps';

import styles from './Dashboard.module.css';

const Dashboard = (props) => {
  const {
    setStepsData,
    addQuestioner,
    stepsData,
  } = props;

  console.log('===stepsData', stepsData);

  const StepComponent = steps[stepsData.step];

  const nextStep = (data = {}, callback) => {
    const newStepsData = {
      ...stepsData,
      ...data,
    };

    if (stepsData.step < steps.length - 1) {
      newStepsData.step++;
      setStepsData(newStepsData);
    } else {
      addQuestioner(newStepsData, callback);
    }
  };

  return (
    <div className={styles.wrapper}>
      <StepComponent
        onSave={nextStep}
        stepsData={stepsData}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  stepsData: state.steps.stepsData,
});

const mapDispatchToProps = {
  setStepsData,
  addQuestioner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);